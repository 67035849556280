import React from "react"
import { Link } from "gatsby"
import Button from "@bit/azheng.joshua-tree.button"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TheaterVideo from "@components/Theater/TheaterVideo"
import ImageSlider from "../components/ImageSlider/ImageSlider"
const wisdomTeethData = require("../_archives/procedures/wisdom-teeth-removal.json")

import "../css/pages/w4w-22.scss"

const W4W = () => {
  const recipients = ["/KOMS/DEV/w4w-post-01.jpg", "/KOMS/DEV/w4w-post-02.jpg"]
  const image = (name, format) => `/Programs/${name}.${format}`
  return (
    <Layout pageTitle="wisdom-page" language={"en"}>
      <SEO
        lang="en"
        title="Wisdom For Wisdom | Kentuckiana Oral Maxillofacial & Dental Implant Surgery"
        description="Follow the Wisdom For Wisdom steps to apply"
      />
      <div className="w4w-22">
        <div className="w4w-22__hero">
          <img
            src={image("w4w-top-left-corner-image", "png")}
            className="w4w-22__hero-tl w4w-22__shapes-img"
            alt="color shapes"
          />
          <img
            src={image("2022-w4w-logo", "svg")}
            className="w4w-22__hero-middle"
            alt="wisdom for wisdom logo"
          />
          <img
            src={image("w4w-top-right-corner-image", "png")}
            className="w4w-22__hero-tr w4w-22__shapes-img"
            alt="colorful shapes"
          />
        </div>

        <div className="w4w-22__about">
          <div className="w4w-22__about-content">
            <h1>Helping Students Save for College!</h1>
            <h2>
              We’re helping a local student save for college by providing them
              with a free wisdom teeth removal procedure through our Wisdom For
              Wisdom program.{" "}
            </h2>
            <p>
              Cassidy, 21, is a student at Western Kentucky University in
              Bowling Green. In her application, she wrote about how her parents
              helped her with braces when she was younger and now that her
              wisdom teeth are in, she doesnt want all of that work
              straightening her teeth to be undone. She finished by writing, “My
              parents do so much for me and I would love to be selected so they
              could have a break on paying for things for me. I work while in
              college but it’s not a lot, so I still need their financial
              support sometimes.”
            </p>
            <p>
              We wish every student the best as they pursue their education.
            </p>
          </div>
        </div>

        <div className="w4w-22__apply">
          <img
            src={image("w4w-mid-left-image", "png")}
            className="w4w-22__apply-tl w4w-22__shapes-img"
            alt="color shapes"
          />
          <div className="w4w-22__apply-content">
            <h2>
              Follow Cassidy’s procedure on{" "}
              <a
                href="https://www.facebook.com/LouisvilleOMFS/"
                title="Follow us on Facebook"
                target="_blank"
              >
                Facebook
              </a>{" "}
              and{" "}
              <a
                href="https://www.instagram.com/kentuckianaoms/"
                title="Follow us on Instagram"
                target="_blank"
              >
                Instagram
              </a>
              .
            </h2>
            <p>
              <i>
                Learn more about{" "}
                <Link
                  to="/services/wisdom-teeth-removal-louisville-ky/"
                  title="Learn more about wisdom teeth removal"
                >
                  wisdom teeth removal
                </Link>
                .
              </i>
            </p>
            {/* <p>
              <strong>How to Apply:</strong>
            </p>
            <ul>
              <li>Watch a video about wisdom teeth extraction.</li>
              <li>Take a short quiz.</li>
              <li>Submit your application.</li>
            </ul>
            <h3>
              We wish every student the best as they pursue their academic
              endeavors.{" "}
            </h3>
            <div className="w4w-22__btn-group">
              <TheaterVideo
                videoUrl={`https://www.youtube.com/watch?v=${wisdomTeethData.youtube}`}
                controls={true}
                playing={true}
                onlyButton={true}
                buttonClass="w4w-22__btn"
              />
              <Button
                className="w4w-22__btn"
                buttonText="Take the Quiz"
                href="/w4w-step-1/"
              />
            </div> */}
            <div className="w4w-22__facts">
              <h3>Just the facts&hellip;</h3>
              <ul>
                <li>
                  Wisdom teeth are the only teeth that don’t form before birth.
                </li>
                <li>
                  85% of wisdom teeth will need to be removed at some point.
                </li>
                <li>Wisdom teeth are also known as third molars.</li>
                <li>
                  Wisdom teeth often appear between the ages of 17 and 25.
                </li>
                <li>
                  Wisdom teeth are removed for a variety of reasons, including
                  the jaw not having the space for them.
                </li>
              </ul>
            </div>
          </div>
          <img
            src={image("w4w-bottom-right-image", "png")}
            className="w4w-22__apply-br w4w-22__shapes-img"
            alt="color shapes"
          />
        </div>
      </div>
      <div className="close-w4w-slider">
        <div
          style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 40 }}
        >
          <img src={recipients[0]} alt="color shapes" />
          <img src={recipients[1]} alt="color shapes" />
        </div>
      </div>
    </Layout>
  )
}

export default W4W
